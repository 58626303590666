/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import AccountService from '../../services/account.service';
import AlertService from '../../services/alerts.service';
import AssetService from '../../services/asset.service';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import FullScreenChartModal from './FullScreenChartModal';
import AlertEditModal from './AlertEditModal';
import CreateJobModal from './CreateJobModal';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import DevicesModal from './DevicesModal';
import memoize from 'memoize-one';
import moment from 'moment';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { CardActionArea } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import IconTooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import NotificationIcon from '@material-ui/icons/NotificationsActiveOutlined';
import ScheduledIcon from '@material-ui/icons/DateRange';
import UnscheduledIcon from '@material-ui/icons/EventBusy';
import ServicedIcon from '@material-ui/icons/EventAvailable';
import GroupsIcon from '@material-ui/icons/People';
import InstallIcon from '@material-ui/icons/SystemUpdate';
import DeviceDownIcon from '@material-ui/icons/MobileOff';
import SignalIcon from '@material-ui/icons/SpeakerPhone';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import FilterIcon from '@material-ui/icons/FilterList';

import DatePicker from 'react-datepicker';
import { Event, Timing, ModalView } from '../GoogleAnalytics';
import { jsAsset, alertCustomer, jsAlert, jsGraph, jsOpportunity, jsUpdateOpportunity, jsGraphData , jsSource} from "../../componentObjects";
import JobDetailModal from './JobDetailModal';

import HighchartGraph from '../Graph/HighchartGraph';
import TriageAlertsTable from '../TriageAlerts/TriageAlertsTable';

import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { TimeRightPanel } from '../Graph/TimeRightPanel';
import { RunRightPanel } from '../Graph/RunRightPanel';
import { ACRightPanel } from '../Graph/ACRightPanel';
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ReferenceArea,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
const styles = theme => ({
    root: {
        marginLeft: '225px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
            width: `calc(100% + 450px)`,
        },
    }
});

class BaseAlertPage extends Component {

    constructor(props) {
        super(props);

        this.onTableTabClick = this.onTableTabClick.bind(this);
        this.changeRadio = this.changeRadio.bind(this);
        this.reloadTable = this.reloadTable.bind(this);
        this.onAlertClick = this.onAlertClick.bind(this);
        this.runAnalytic = this.runAnalytic.bind(this);
        this.runDailyAnalytic = this.runDailyAnalytic.bind(this);

        this.openFullRunModal = this.openFullRunModal.bind(this);
        this.showFullRunModal = this.showFullRunModal.bind(this);
        this.openFullDailyModal = this.openFullDailyModal.bind(this);
        this.showFullDailyModal = this.showFullDailyModal.bind(this);
        this.openFullMultiModal = this.openFullMultiModal.bind(this);
        this.showFullMultiModal = this.showFullMultiModal.bind(this);

        this.openJobDetailModal = this.openJobDetailModal.bind(this);
        this.showJobDetailModal = this.showJobDetailModal.bind(this);

        this.editAlert = this.editAlert.bind(this);
        this.onEditAlert = this.onEditAlert.bind(this);
        this.openEditAlertModal = this.openEditAlertModal.bind(this);
        this.showEditAlertModal = this.showEditAlertModal.bind(this);

        this.createJob = this.createJob.bind(this);
        this.onCreateJob = this.onCreateJob.bind(this);
        this.openCreateJobModal = this.openCreateJobModal.bind(this);
        this.showCreateJobModal = this.showCreateJobModal.bind(this);

        this.closeAlert = this.closeAlert.bind(this);
        this.onCloseAlert = this.onCloseAlert.bind(this);
        this.openCloseAlertModal = this.openCloseAlertModal.bind(this);
        this.showCloseAlertModal = this.showCloseAlertModal.bind(this);

        this.viewDevices = this.viewDevices.bind(this);
        this.onViewDevices = this.onViewDevices.bind(this);
        this.openDevicesModal = this.openDevicesModal.bind(this);
        this.showDevicesModal = this.showDevicesModal.bind(this);

        this.onPriorityChange = this.onPriorityChange.bind(this);
        this.onPriorityOpportunityChange = this.onPriorityOpportunityChange.bind(this);
        this.onMessageChange = this.onMessageChange.bind(this);
        this.onRecommendationChange = this.onRecommendationChange.bind(this);
        this.onFalsePositiveChange = this.onFalsePositiveChange.bind(this);
        this.onCommentsChange = this.onCommentsChange.bind(this);
        this.onSubjectChange = this.onSubjectChange.bind(this);
        this.onChangeStart = this.onChangeStart.bind(this);
        this.onChangeEnd = this.onChangeEnd.bind(this);
        this.onViewGraph = this.onViewGraph.bind(this);

        this.onLeftRunClick = this.onLeftRunClick.bind(this);
        this.onRightRunClick = this.onRightRunClick.bind(this);
        this.onChangeRun = this.onChangeRun.bind(this);

        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.renderACGraph = this.renderACGraph.bind(this);

        this.getHighchartsData = this.getHighchartsData.bind(this);

        this.state = {
            alertArgs: {
                queryId: "custom",
                startTime: null,
                endTime: null,
                isActive: true,
                priority: 3
            },
            currentCustomer: alertCustomer,
            currentAlert: jsAlert,
            updateAlert: jsAlert,
            editAlert: jsAlert,
            currentOpportunity: jsUpdateOpportunity,
            currentAsset: jsAsset,
            currentGraph: {
                run: {},
                daily: {}
            },
            dailyGraph: {},
            isSelected: -1,
            alertType: "Active",
            alertClassId: "5",
            alertDetailLoading: false,
            graphLoading: false,
            alertSelected: false,
            acSelected: false,
            acGraph: false,
            acHvacSystem: [],
            currentRunName: "",
            currentSource: jsSource,
            currentMax: 0,
            runList: [],
            selectedRun: 0,
            numRuns: 0,
            runGraphData: jsGraphData,
            dailyGraphData: jsGraphData,
            runGraphDataAnnotated: jsGraphData,
            dailyGraphDataAnnotated: jsGraphData,
            runGraphAnnotations: [],
            runGraphArrowAnnotations: [],
            dailyGraphAnnotations: [],
            dailyGraphArrowAnnotations: [],
            currentGraphAnnotations: [],
            currentGraphArrowAnnotations: [],
            showRunAnnotations: false,
            showDailyAnnotations: false,
            canLeftClick: false,
            canRightClick: true,
            untrainedRun: false,
            graphModalType: "Run",
            modalTitle: "",
            modalMessage: "",
            message: "",
            showRunModal: false,
            showDailyModal: false,
            showMultiModal: false,
            currentAnalyticProps: {},
            showEditConfirm: false,
            showCreateJobConfirm: false,
            showCloseConfirm: false,
            showDevicesConfirm: false,
            unsetProperties: [],
            showJobDetailConfirm: false,
            casesLoading: false,
            alertCases: [],
            multiseriesGraph: {},

            runDataList: [],
            timelineDataList: [],
            multiDataList: [],

            property_dictionary: { "75": [766057, 754696], "77": [754702, 768809], "76": [754686, 779446, 779443, 782721, 782722] },
            startDateTimeStr: "",
            endDateTimeStr: "",
            dpStartDateTime: null,
            dpEndDateTime: null,
            dpStartStatus: null,
            dpEndStatus: null,
            selectedStartTime: null,
            selectedEndTime: null,
            showZoomButton: false,
            hasAnalytics: false,
            visibility: {
                analytics: false,
                furnace: true,
                temp: true,
                rla: false,
                lra: false
            },
            tabIndex: 0,
            tableIndex: 0
        }
    }

    handleCheckbox(e) {
        const checkedVal = e.target.value;

        if (e.target.checked) {
            this.setState(prevState => ({
                visibility: {
                    ...prevState.visibility,
                    [checkedVal]: true
                }
            }));
        }
        else {
            this.setState(prevState => ({
                visibility: {
                    ...prevState.visibility,
                    [checkedVal]: false
                }
            }));
        }
    }

    async onChangeRun(e) {
        Event("Run Dropdown Click", "User selected a run from the dropdown", "Run Drowndown Clicked");
        let index = parseInt(e.target.value);
        let canLeft = index > 0 ? true : false;
        let canRight = index < this.state.numRuns - 1 ? true : false;

        this.setState({
            currentRunName: this.state.currentGraph.runNamesList[index],
            currentSource: this.state.currentGraph.timeSeriesDataSources[index],
            clearPreviusState: true,
            selectedRun: index,
            canLeftClick: canLeft,
            canRightClick: canRight
        }, () => {
            var runChar = this.state.currentRunName.charAt(0);
            if (runChar == 'g' || runChar == 'r')
                this.setState({ untrainedRun: false });
            else
                this.setState({ untrainedRun: true });
        });
    }

    async onLeftRunClick() {
        Event("Run Left Click", "User clicked left arrow to change run", "Left Run Clicked");
        let index = this.state.selectedRun - 1;
        let canLeft = index > 0 ? true : false;
        let canRight = index < this.state.numRuns - 1 ? true : false;

        this.setState({
            currentRunName: this.state.currentGraph.runNamesList[index],
            currentSource: this.state.currentGraph.timeSeriesDataSources[index],
            clearPreviusState: true,
            selectedRun: index,
            canLeftClick: canLeft,
            canRightClick: canRight
        }, () => {
            var runChar = this.state.currentRunName.charAt(0);
            if (runChar == 'g' || runChar == 'r')
                this.setState({ untrainedRun: false });
            else
                this.setState({ untrainedRun: true });
        });
    }

    async onRightRunClick() {
        Event("Run Right Click", "User clicked right arrow to change run", "Right Run Clicked");
        let index = this.state.selectedRun + 1;
        let canLeft = index > 0 ? true : false;
        let canRight = index < this.state.numRuns - 1 ? true : false;

        this.setState({
            currentRunName: this.state.currentGraph.runNamesList[index],
            currentSource: this.state.currentGraph.timeSeriesDataSources[index],
            clearPreviusState: true,
            selectedRun: index,
            canLeftClick: canLeft,
            canRightClick: canRight
        }, () => {
            var runChar = this.state.currentRunName.charAt(0);
            if (runChar == 'g' || runChar == 'r')
                this.setState({ untrainedRun: false });
            else
                this.setState({ untrainedRun: true });
        });

    }

    showFullRunModal() {
        this.setState({
            showRunModal: !this.state.showRunModal
        }, () => {
                if (this.state.showRunModal)
                    ModalView("fullscreenRunGraph");
        });
        window.scrollTo(0, 0);
    }

    openFullRunModal() {
        this.setState({ modalTitle: "Run Graph" }, () => {
            this.showFullRunModal();
        });
    }

    showFullDailyModal() {
        this.setState({
            showDailyModal: !this.state.showDailyModal
        }, () => {
                if (this.state.showDailyModal)
                    ModalView("fullscreenDailyGraph");
        });
        window.scrollTo(0, 0);
    }

    openFullDailyModal() {
        this.setState({ modalTitle: "Timeline Graph" }, () => {
            this.showFullDailyModal();
        });
    }

    showFullMultiModal() {
        this.setState({
            showMultiModal: !this.state.showMultiModal
        }, () => {
            if (this.state.showMultiModal)
                ModalView("fullscreenMultiseriesGraph");
        });
        window.scrollTo(0, 0);
    }

    openFullMultiModal() {
        this.setState({ modalTitle: "MultiSeries Graph" }, () => {
            this.showFullMultiModal();
        });
    }

    runAnalytic() {
        this.setState({ showRunAnnotations: !this.state.showRunAnnotations });
    }

    runDailyAnalytic() {
        this.setState({ showDailyAnnotations: !this.state.showDailyAnnotations });
    }

    openJobDetailModal(alert) {
        this.setState({ editAlert: alert }, () => { this.showJobDetailModal(null) });
    }

    showJobDetailModal() {
        this.setState({
            showJobDetailConfirm: !this.state.showJobDetailConfirm
        }, () => {
                if (this.state.showJobDetailConfirm)
                    ModalView("jobDetailConfirm");
        });
        window.scrollTo(0, 0);
    }

    async getAlertCases(alertId) {
        await AlertService.getalertcases(alertId)
            .then(response => {
                if (this.props._isMounted)
                    this.setState({ alertCases: response.data });
            })
            .catch(e => {
                console.log(e);
            });
    }

    openEditAlertModal(alert) {
        this.setState({ modalTitle: "Edit Alert", editAlert: alert }, () => { this.showEditAlertModal(null) });
    }

    showEditAlertModal() {
        this.setState({
            showEditConfirm: !this.state.showEditConfirm
        }, () => {
                if (this.state.showEditConfirm)
                    ModalView("editAlertConfirm");
        });
        window.scrollTo(0, 0);
    }

    onEditAlert() {
        this.editAlert();
        this.setState({ showEditConfirm: !this.state.showEditConfirm });
    }

    async editAlert() {
        await AlertService.updatealert(this.state.editAlert)
            .then(async response => {
                if (response.status === 200 && response.data !== undefined && response.data !== null && response.data !== false) {
                    this.setState({
                        message: "The alert was updated successfully!"
                    });
                    Event("Alert Update Clicked", "User clicked update alert button", "Alert Update Clicked");

                    this.reloadTable();
                }
                else {
                    this.setState({
                        message: "Failed to update the alert!"
                    });
                }

                this.setState({ loading: true }, () => {
                    this.props.reloadTable();
                });

                setTimeout(() => {
                    this.setState({
                        message: ""
                    });
                }, 3000);
            })
            .catch(e => {
                console.log(e);
            });
    }

    openCreateJobModal(alert, opportunity) {
        this.setState({ modalTitle: "Create Job", editAlert: alert, currentOpportunity: opportunity },
            () => {
                this.setState(prevState => ({
                    currentOpportunity: {
                        ...prevState.currentOpportunity,
                        subject: this.state.editAlert.message
                    }
                }));
                this.showCreateJobModal(null);
            });
    }

    showCreateJobModal() {
        this.setState({
            showCreateJobConfirm: !this.state.showCreateJobConfirm
        }, () => {
                ModalView("createJobModal");
        });
        window.scrollTo(0, 0);
    }

    onCreateJob() {
        this.createJob();
        this.setState({ showCreateJobConfirm: !this.state.showCreateJobConfirm });
    }

    async createJob() {
        this.setState(prevState => ({
            editAlert: {
                ...prevState.editAlert,
                priority: 1,
                priorityName: "High",
                isFalsePositive: false 
            }
        }), async () => {
            await AlertService.updatealert(this.state.editAlert)
                .then(response => {
                    if (response.status === 200 && response.data !== undefined && response.data !== null && response.data !== false) {
                        this.setState(prevState => ({
                            currentOpportunity: {
                                ...prevState.currentOpportunity
                            }
                        }), async () => {
                            await AlertService.createjob(this.state.editAlert.alertId, this.state.currentOpportunity)
                                .then(response2 => {
                                    if (response2.status === 200 && response2.data !== undefined && response2.data !== null && response2.data !== false) {
                                        var localeDateAlert = new Date(this.state.editAlert.startTime);
                                        var msg = `Job created for alert occurring on ${localeDateAlert.toLocaleDateString()}, ${localeDateAlert.toLocaleTimeString()}.`;
                                        this.setState({
                                            message: msg
                                        });
                                        Event("Job Created from Alert", "User clicked create job button", "Job Created from Alert");

                                        this.reloadTable();
                                    }
                                    else {
                                        this.setState({
                                            message: "Failed to create job from the alert!"
                                        });
                                    }

                                    setTimeout(() => {
                                        this.setState({
                                            message: ""
                                        });
                                    }, 3000);
                                });
                        });
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        }
        );

    }

    openCloseAlertModal(alert) {
        var localeDateAlert = new Date(alert.startTime);
        this.setState({
            modalTitle: "Close Alert",
            modalMessage: `You are about to close this alert from ${localeDateAlert.toLocaleDateString()}, ${localeDateAlert.toLocaleTimeString()}. Are you sure?`,
            editAlert: alert
        }, () => { this.showCloseAlertModal(null) });
    }

    showCloseAlertModal() {
        this.setState({
            showCloseConfirm: !this.state.showCloseConfirm
        });
        window.scrollTo(0, 0);
    }

    onCloseAlert() {
        this.closeAlert();
        this.setState({ showCloseConfirm: !this.state.showCloseConfirm });
    }

    async closeAlert() {
        var localeDateAlert = new Date(this.state.editAlert.startTime);
        await AlertService.closealert(this.state.editAlert.alertId)
            .then(response => {
                if (response.status === 200 && response.data !== undefined && response.data !== null && response.data !== false) {
                    this.setState({
                        message: `Alert occurring on ${localeDateAlert.toLocaleDateString()}, ${localeDateAlert.toLocaleTimeString()} closed.`
                    });
                    Event("Close Alert Clicked", "User clicked button to close alert", "Close Alert Clicked");

                    this.reloadTable();
                }
                else {
                    this.setState({
                        message: "Failed to close the alert!"
                    });
                }

                setTimeout(() => {
                    this.setState({
                        message: ""
                    });
                }, 3000);
            })
            .catch(e => {
                console.log(e);
            });
    }

    openDevicesModal(alert) {
        this.setState({
            modalTitle: "View Devices",
            modalMessage: `You are about to be taken to the Devices page for the alert customer, ${alert.organizationName}. Would you like to continue?`,
            editAlert: alert
        }, () => { this.showDevicesModal(null) });
    }

    showDevicesModal() {
        this.setState({
            showDevicesConfirm: !this.state.showDevicesConfirm
        });
        window.scrollTo(0, 0);
    }

    onViewDevices() {
        this.viewDevices();
        this.setState({ showDevicesConfirm: !this.state.showDevicesConfirm });
    }

    viewDevices() {
        if (this.props.showButtons) {
            this.props.setCurrentPartnerId(this.state.currentCustomer.parentOrganizationId, this.setPartnerCallback());
        }
        else {
            this.props.setActivePath("/devices");
        }
    }

    onPriorityChange(e) {
        const priority = e.target.value;

        this.setState(prevState => ({
            editAlert: {
                ...prevState.editAlert,
                priority: parseInt(priority)
            }
        }));
    }

    onPriorityOpportunityChange(e) {
        const priority = e.target.value;

        this.setState(prevState => ({
            currentOpportunity: {
                ...prevState.currentOpportunity,
                priority: parseInt(priority)
            }
        }));
    }

    onFalsePositiveChange(e) {
        const isFalsePositive = e.target.value || null;
        var isFalsePositiveBool = false;

        if (isFalsePositive != null) {
            if (isFalsePositive === "true")
                isFalsePositiveBool = true;
            else
                isFalsePositiveBool = false;

            this.setState(prevState => ({
                editAlert: {
                    ...prevState.editAlert,
                    isFalsePositive: isFalsePositiveBool
                }
            }));
        }
        else {
            this.setState(prevState => ({
                editAlert: {
                    ...prevState.editAlert,
                    isFalsePositive: isFalsePositive
                }
            }));
        }
    }

    onMessageChange(e) {
        const message = e.target.value;

        this.setState(prevState => ({
            editAlert: {
                ...prevState.editAlert,
                message: message
            }
        }));
    }

    onCommentsChange(e) {
        const comment = e.target.value;

        this.setState(prevState => ({
            currentOpportunity: {
                ...prevState.currentOpportunity,
                comment: comment
            }
        }));
    }

    onSubjectChange(e) {
        const subject = e.target.value;

        this.setState(prevState => ({
            currentOpportunity: {
                ...prevState.currentOpportunity,
                subject: subject
            },
            editAlert: {
                ...prevState.editAlert,
                message: subject
            }
        }));
    }

    onRecommendationChange(e) {
        const recommendation = e.target.value;

        this.setState(prevState => ({
            editAlert: {
                ...prevState.editAlert,
                comment: recommendation
            }
        }));
    }

    onChangeStart(e) {
        var start = moment(e).toDate();
        var startStr = moment(e).format();
        this.setState({ dpStartDateTime: start, dpStartStatus: start, startDateTimeStrPlus: startStr }, () => {
            this.setState(prevState => ({
                currentAlert: {
                    ...prevState.currentAlert,
                    startTime: start
                }
            }), () => {
                    this.setState({ updateAlert: this.state.currentAlert });
            });
        });
    }

    onChangeEnd(e) {
        var end = moment(e).toDate();
        var endStr = moment(e).format();
        this.setState({ dpEndDateTime: end, endDateTimeStrPlus: endStr }, () => {
            this.setState(prevState => ({
                currentAlert: {
                    ...prevState.currentAlert,
                    endTime: end
                }
            }), () => {
                    this.setState({ updateAlert: this.state.currentAlert });
            });
        });
    }

    async onViewGraph() {
        let selectedAlert = this.state.isSelected;
        this.setState({ selectedStartTime: this.state.dpStartDateTime, selectedEndTime: this.state.dpEndDateTime }, async () => {
            await this.onAlertClick(selectedAlert, true);
            this.setState({ dpStartStatus: null, dpEndStatus: null }); 
        });
    }

    async reloadTable() {
        this.setState({
            alertTableLoading: true,
            isSelected: -1,
            alertSelected: false,
            alertList: [],
            currentCustomer: alertCustomer,
            currentAsset: jsAsset,
            currentAlert: jsAlert,
            currentGraph: {},
            runGraphData: jsGraphData,
            dailyGraphData: jsGraphData,
            runGraphDataAnnotated: jsGraphData,
            dailyGraphDataAnnotated: jsGraphData,
            multiseriesGraph: {},
            runDataList: [],
            timelineDataList: [],
            multiDataList: [],
        });

        this.setState(prevState => ({
            alertArgs: {
                ...prevState.alertArgs,
                isActive: this.state.alertType === "Inactive" ? false : true
            }
        }));

        this.props.reloadTable();
        //this.props.getAlerts(this.props.currentCustomerId);
    }

    changeRadio(e) {
        let x = e.target.value;

        this.setState({
            alertType: x,
            isSelected: -1,
            selectedRun: 0,
            numRuns: 0,
            acGraph: false,
            acSelected: false,
            alertSelected: false,
            alertList: [],
            runList: [],
            runNamesList: [],
            currentCustomer: alertCustomer,
            currentAsset: jsAsset,
            currentAlert: jsAlert,
            currentGraph: {},
            //currentRun: null,
            currentRunName: "",
            currentSource: jsSource,
            runGraphData: jsGraphData,
            dailyGraphData: jsGraphData,
            runGraphDataAnnotated: jsGraphData,
            dailyGraphDataAnnotated: jsGraphData,
            multiseriesGraph: {},
            runDataList: [],
            timelineDataList: [],
            multiDataList: [],
            canLeftClick: false,
            canRightClick: false
        });

        this.setState(prevState => ({
            alertArgs: {
                ...prevState.alertArgs,
                isActive: x === "Inactive" ? false : true
            }
        }));

        this.setState({ alertTableLoading: true }, () => {
            this.props.changeRadio(x); 
        });
    }

    onTableTabClick = (index) => {
        let x = index;

        this.setState({
            tableIndex: x
        });
    }

    async onAlertClick(index, updateGraph = false) {
        let x = index;
        let prevX = this.state.isSelected;
        var startTime = performance.now();
        Event("Alert Clicked", "User clicked on an alert in an alert table", "Alert Clicked");
        if (x !== prevX || updateGraph) {
            this.setState({ multiseriesGraph: {}, runDataList: [], timelineDataList: [], multiDataList: [], currentAlert: jsAlert,
                currentCustomer: alertCustomer, currentAsset: jsAsset, hasAnalytics: false, alertDetailLoading: true, isSelected: index,
                acSelected: false, acGraph: false, acHvacSystem: [] }, () => {
                this.setState({
                    currentAlert: x !== prevX ? this.props.alertList[x] : this.state.updateAlert,
                }, async () => {
                    if (this.state.dpStartStatus == null && this.state.dpEndStatus == null) {
                        this.setState({
                            selectedStartTime: this.state.alertType === "Active" ? "" : this.state.currentAlert.startTime,
                            selectedEndTime: this.state.alertType === "Active" ? this.state.currentAlert.startTime : this.state.currentAlert.endTime,
                        });
                    }
                    if (this.state.alertType === "Repairs")
                        this.getAlertCases(this.state.currentAlert.alertId);

                    await AlertService.get(this.state.currentAlert.organization)
                        .then(response => {
                            if (this.props._isMounted)
                                this.setState({
                                    currentCustomer: response.data
                                }, async () => {
                                    await AlertService.getalertasset(this.state.currentAlert)
                                        .then(response2 => {
                                            if (this.props._isMounted)
                                                this.setState({
                                                    currentAsset: response2.data
                                                }, async () => {
                                                    var acSelected = false;
                                                    var hvacSysList = [];
                                                    if (this.state.currentAlert?.assetClassNames?.some(a => a === "AirConditioner")) {
                                                        acSelected = true;
                                                        await AssetService.get(this.state.currentAlert.pathIds[1])
                                                            .then(assetResponse => {
                                                                if (this.props._isMounted)
                                                                    this.setState({ acHvacSystem: assetResponse.data });
                                                            });
                                                    }

                                                    if (this.state.alertType !== "Device") {
                                                        let propertySet = Object.hasOwnProperty.call(this.state.property_dictionary, this.state.currentAlert.typeId);
                                                        if (propertySet) {
                                                            if (this.state.dpStartStatus == null && this.state.dpEndStatus == null && x !== prevX) {
                                                                var alertStart = new Date(this.state.currentAlert.startTime);
                                                                alertStart = alertStart.setDate(alertStart.getDate() - 30);
                                                                var alertStartStr = moment(alertStart).format();
                                                                this.setState({
                                                                    dpStartDateTime: this.state.alertType === "Active" ? alertStart : new Date(this.state.currentAlert.startTime),
                                                                    dpEndDateTime: this.state.alertType === "Active" ? new Date(this.state.currentAlert.startTime) : new Date(this.state.currentAlert.endTime)
                                                                });
                                                            }
                                                            else if (this.state.dpStartStatus == null && this.state.dpEndStatus == null && x == prevX) {
                                                                this.setState({
                                                                    selectedStartTime: this.state.currentAlert.startTime,
                                                                    selectedEndTime: this.state.currentAlert.endTime,
                                                                    dpStartDateTime: new Date(this.state.currentAlert.startTime),
                                                                    dpEndDateTime: new Date(this.state.currentAlert.endTime)
                                                                });
                                                            }

                                                            await AlertService.getmultiseriesgraphdata({
                                                                assetId: parseInt(this.state.currentAlert.assetId),
                                                                propertyList: this.state.property_dictionary[this.state.currentAlert.typeId],
                                                                startTime: this.state.selectedStartTime,
                                                                endTime: this.state.selectedEndTime,
                                                                maxPoints: 800,
                                                                summaryType: 1
                                                            }, acSelected ? this.state.currentAlert.pathIds[1] : null)
                                                                .then(response3 => {
                                                                    if (this.props._isMounted)
                                                                        this.setState({
                                                                            multiseriesGraph: response3.data.trend,
                                                                            currentGraph: response3.data.run,
                                                                            acSelected: acSelected,
                                                                            acGraph: acSelected,
                                                                            alertDetailLoading: false,
                                                                            alertSelected: true,
                                                                            hasAnalytics: this.state.currentAsset.hasAnalytics,
                                                                            tabIndex: 1
                                                                        }, () => {
                                                                            if (this.state.currentGraph.timeSeriesDataSources !== null) {
                                                                                this.getHighchartsData();

                                                                                this.setState({
                                                                                    currentRunName: this.state.currentGraph.runNamesList[0],
                                                                                    currentSource: this.state.currentGraph.timeSeriesDataSources[0],
                                                                                    runList: this.state.currentGraph.timeSeriesDataSources,
                                                                                    numRuns: this.state.currentGraph.timeSeriesDataSources.length,
                                                                                    canLeftClick: false,
                                                                                    canRightClick: true
                                                                                }, () => {
                                                                                    const runName = this.state.currentRunName;
                                                                                    this.setState({
                                                                                        untrainedRun: runName !== undefined ? (runName.charAt(0) == 'g' ? false : (runName.charAt(0) == 'r' ? false : true)) : false,
                                                                                        alertDetailLoading: false,
                                                                                        alertSelected: true
                                                                                    }, () => {
                                                                                        var elapsedTime = performance.now() - startTime;
                                                                                        Timing("Alert Detail Loading", "alertDetailLoading", elapsedTime, "Alert Detail Loading");
                                                                                    });
                                                                                });
                                                                            }
                                                                            else {
                                                                                this.setState({
                                                                                    alertDetailLoading: false,
                                                                                    alertSelected: true,
                                                                                    numRuns: 0,
                                                                                    currentRunName: "",
                                                                                    currentSource: jsSource,
                                                                                    canLeftClick: false,
                                                                                    canRightClick: false
                                                                                }, () => {
                                                                                    var elapsedTime = performance.now() - startTime;
                                                                                    Timing("Alert Detail Loading", "alertDetailLoading", elapsedTime, "Alert Detail Loading");
                                                                                });
                                                                            }
                                                                        });
                                                                });

                                                        }
                                                        else {
                                                            /** START EDIT SECTION **/

                                                            if (this.state.dpStartStatus == null && this.state.dpEndStatus == null && x !== prevX) {
                                                                var alertStart = new Date(this.state.currentAlert.startTime);
                                                                alertStart.setMinutes(alertStart.getMinutes() - 5);
                                                                var dailyStart = alertStart.setHours(alertStart.getHours() - 6);
                                                                var dailyEnd = alertStart.setHours(alertStart.getHours() + 12);
                                                                this.setState({
                                                                    dpStartDateTime: dailyStart,
                                                                    dpEndDateTime: dailyEnd
                                                                }, async () => {
                                                                    if (!acSelected) {
                                                                        await AlertService.getallgraphdata(this.state.currentAsset.offHigh, this.state.currentAsset.furnaceId, this.state.currentAsset.acCurrentPropId, this.state.currentAsset.furnaceCurrentPropId, this.state.currentAlert)
                                                                            .then(response3 => {
                                                                                if (this.props._isMounted)
                                                                                    this.setState({
                                                                                        currentGraph: response3.data,
                                                                                        hasAnalytics: this.state.currentAsset.hasAnalytics,
                                                                                        acGraph: false
                                                                                    }, () => {
                                                                                        this.getHighchartsData();

                                                                                        this.setState({
                                                                                            alertDetailLoading: false,
                                                                                            alertSelected: true
                                                                                        }, () => {
                                                                                            var elapsedTime = performance.now() - startTime;
                                                                                            Timing("Alert Detail Loading", "alertDetailLoading", elapsedTime, "Alert Detail Loading");
                                                                                        });
                                                                                    });
                                                                            });
                                                                    }
                                                                    else {
                                                                        /** todo AC GRAPH **/
                                                                        await AlertService.getacalertgraphdata(this.state.currentAlert.assetId, this.state.currentAlert.startTime, this.state.currentAlert.endTime, this.state.currentAlert.pathIds[1], this.state.acHvacSystem.length ? this.state.acHvacSystem : [])
                                                                            .then(response5 => {
                                                                                if (this.props._isMounted) {
                                                                                    this.setState({
                                                                                        currentGraph: response5.data,
                                                                                        hasAnalytics: this.state.currentAsset.hasAnalytics,
                                                                                        acGraph: true,
                                                                                        acSelected: true,
                                                                                        selectedRun: 0
                                                                                    }, () => {
                                                                                        if (this.state.currentGraph.timeSeriesDataSources !== null) {
                                                                                            this.getHighchartsData();

                                                                                            this.setState({
                                                                                                currentRunName: this.state.currentGraph.runNamesList[0],
                                                                                                currentSource: this.state.currentGraph.timeSeriesDataSources[0],
                                                                                                runList: this.state.currentGraph.timeSeriesDataSources,
                                                                                                numRuns: this.state.currentGraph.timeSeriesDataSources.length,
                                                                                                canLeftClick: false,
                                                                                                canRightClick: true
                                                                                            }, () => {
                                                                                                const runName = this.state.currentRunName;
                                                                                                this.setState({
                                                                                                    untrainedRun: runName !== undefined ? (runName.charAt(0) == 'g' ? false : (runName.charAt(0) == 'r' ? false : true)) : false,
                                                                                                    alertDetailLoading: false,
                                                                                                    alertSelected: true
                                                                                                }, () => {
                                                                                                    var elapsedTime = performance.now() - startTime;
                                                                                                    Timing("Alert Detail Loading", "alertDetailLoading", elapsedTime, "Alert Detail Loading");
                                                                                                });
                                                                                            });
                                                                                        }
                                                                                        else {
                                                                                            this.setState({
                                                                                                alertDetailLoading: false,
                                                                                                alertSelected: true,
                                                                                                numRuns: 0,
                                                                                                currentRunName: "",
                                                                                                currentSource: jsSource,
                                                                                                canLeftClick: false,
                                                                                                canRightClick: false
                                                                                            }, () => {
                                                                                                var elapsedTime = performance.now() - startTime;
                                                                                                Timing("Alert Detail Loading", "alertDetailLoading", elapsedTime, "Alert Detail Loading");
                                                                                            });
                                                                                        }
                                                                                    });
                                                                                }
                                                                            });
                                                                    }
                                                                });
                                                            }

                                                            else if (this.state.dpStartStatus == null && this.state.dpEndStatus == null && x == prevX) {
                                                                this.setState({
                                                                    dpStartDateTime: new Date(this.state.currentAlert.startTime),
                                                                    dpEndDateTime: new Date(this.state.currentAlert.endTime)
                                                                }, async () => {
                                                                    // update daily/timeline graph functionality here
                                                                    let startStr = moment(this.state.dpStartDateTime).format();
                                                                    let endStr = moment(this.state.dpEndDateTime).format();
                                                                    if (!acSelected) {
                                                                        await AlertService.getallgraphdata(this.state.currentAsset.offHigh, this.state.currentAsset.furnaceId, this.state.currentAsset.acCurrentPropId, this.state.currentAsset.furnaceCurrentPropId, this.state.currentAlert)
                                                                            .then(response4 => {
                                                                                if (this.props._isMounted)
                                                                                    this.setState(prevState => ({
                                                                                        currentGraph: response4.data,
                                                                                        hasAnalytics: this.state.currentAsset.hasAnalytics
                                                                                    }), () => {
                                                                                        this.getHighchartsData();
                                                                                        this.setState({
                                                                                            alertDetailLoading: false,
                                                                                            alertSelected: true
                                                                                        })
                                                                                    });
                                                                            })
                                                                            .catch(e => {
                                                                                console.log(e);
                                                                            });
                                                                    }
                                                                    else {
                                                                        /** todo AC GRAPH **/
                                                                        await AlertService.getacalertgraphdata(this.state.currentAlert.assetId, this.state.currentAlert.startTime, this.state.currentAlert.endTime, this.state.currentAlert.pathIds[1], this.state.acHvacSystem.length ? this.state.acHvacSystem : [])
                                                                            .then(response5 => {
                                                                                if (this.props._isMounted) {
                                                                                    this.setState(prevState => ({
                                                                                        currentGraph: {
                                                                                            ...prevState.currentGraph,
                                                                                            daily: response5.data.daily
                                                                                        },
                                                                                        hasAnalytics: this.state.currentAsset.hasAnalytics,
                                                                                        acGraph: true,
                                                                                        acSelected: true
                                                                                    }), () => {
                                                                                        this.getHighchartsData();

                                                                                        this.setState({
                                                                                            alertDetailLoading: false,
                                                                                            alertSelected: true
                                                                                        });
                                                                                    });
                                                                                }
                                                                            })
                                                                            .catch(e => {
                                                                                console.log(e);
                                                                            });
                                                                    }
                                                                });
                                                            }
                                                            else {
                                                                if (!acSelected) {
                                                                    await AlertService.getallgraphdata(this.state.currentAsset.offHigh, this.state.currentAsset.furnaceId, this.state.currentAsset.acCurrentPropId, this.state.currentAsset.furnaceCurrentPropId, this.state.currentAlert)
                                                                        .then(response3 => {
                                                                            if (this.props._isMounted)
                                                                                this.setState({
                                                                                    currentGraph: response3.data,
                                                                                    multiseriesGraph: null
                                                                                }, () => {
                                                                                    this.getHighchartsData();

                                                                                    this.setState({
                                                                                        alertDetailLoading: false,
                                                                                        alertSelected: true,
                                                                                        hasAnalytics: this.state.currentAsset.hasAnalytics
                                                                                    }, () => {
                                                                                        var elapsedTime = performance.now() - startTime;
                                                                                        Timing("Alert Detail Loading", "alertDetailLoading", elapsedTime, "Alert Detail Loading");
                                                                                    });
                                                                                });
                                                                        });
                                                                }
                                                                else {
                                                                    /** todo AC GRAPH **/
                                                                    await AlertService.getacalertgraphdata(this.state.currentAlert.assetId, this.state.currentAlert.startTime, this.state.currentAlert.endTime, this.state.currentAlert.pathIds[1], this.state.acHvacSystem.length ? this.state.acHvacSystem : [])
                                                                        .then(response5 => {
                                                                            if (this.props._isMounted) {
                                                                                this.setState({
                                                                                    currentGraph: response5.data,
                                                                                    multiseriesGraph: null,
                                                                                    hasAnalytics: this.state.currentAsset.hasAnalytics,
                                                                                    acGraph: true,
                                                                                    acSelected: true,
                                                                                    selectedRun: 0
                                                                                }, () => {
                                                                                    if (this.state.currentGraph.timeSeriesDataSources !== null && this.state.currentGraph.timeSeriesDataSources.length) {
                                                                                        this.getHighchartsData();

                                                                                        this.setState({
                                                                                            currentRunName: this.state.currentGraph.runNamesList[0],
                                                                                            currentSource: this.state.currentGraph.timeSeriesDataSources[0],
                                                                                            runList: this.state.currentGraph.timeSeriesDataSources,
                                                                                            numRuns: this.state.currentGraph.timeSeriesDataSources.length,
                                                                                            canLeftClick: false,
                                                                                            canRightClick: true
                                                                                        }, () => {
                                                                                            const runName = this.state.currentRunName;
                                                                                            this.setState({
                                                                                                untrainedRun: runName !== undefined ? (runName.charAt(0) == 'g' ? false : (runName.charAt(0) == 'r' ? false : true)) : false,
                                                                                                alertDetailLoading: false,
                                                                                                alertSelected: true
                                                                                            }, () => {
                                                                                                var elapsedTime = performance.now() - startTime;
                                                                                                Timing("Alert Detail Loading", "alertDetailLoading", elapsedTime, "Alert Detail Loading");
                                                                                            });
                                                                                        });
                                                                                    }
                                                                                    else {
                                                                                        this.setState({
                                                                                            currentRunName: "",
                                                                                            currentSource: jsSource,
                                                                                            runList: [],
                                                                                            numRuns: 0,
                                                                                            canLeftClick: false,
                                                                                            canRightClick: false,
                                                                                            alertDetailLoading: false,
                                                                                            alertSelected: true
                                                                                        }, () => {
                                                                                            var elapsedTime = performance.now() - startTime;
                                                                                            Timing("Alert Detail Loading", "alertDetailLoading", elapsedTime, "Alert Detail Loading");
                                                                                        });
                                                                                    }
                                                                                });
                                                                            }
                                                                        });
                                                                }
                                                            }

                                                            /** END EDIT SECTION **/
                                                        }
                                                    }
                                                    else {
                                                        this.setState({ alertSelected: true, acSelected: false, acGraph: false, alertDetailLoading: false, hasAnalytics: this.state.currentAsset.hasAnalytics });
                                                    }
                                                    //this.setState({ alertSelected: true, alertDetailLoading: false });
                                                });
                                        });
                                });
                        })
                        .catch(e => {
                            console.log(e);
                        });
                });
            });
        }
    }

    async getHighchartsData() {
        var graph = this.state.currentGraph;
        var multiGraph = this.state.multiseriesGraph;

        var runDataList = [];
        var timelineDataList = [];
        var multiDataList = [];
        var rlaVal = 0;

        if (graph !== null && graph !== undefined) {

            if (this.state.acSelected) {
                if (graph.highchartsRun !== null && graph.highchartsRun !== undefined && graph.highchartsRun.data !== null && graph.highchartsRun.data !== undefined && graph.highchartsRun.data.length) {
                    let currentHCSource = graph.highchartsRun;
                    let dataArr = currentHCSource.data.map((item) => item[1]);
                    let pointMoment = moment(currentHCSource.data[0][0]).add(moment().utcOffset() * -1, 'minutes').format("l h:mm:ss A")
                    var dt = new Date(pointMoment);
                    var pointStartMoment = Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate(), dt.getHours(), dt.getMinutes(), dt.getSeconds(), dt.getMilliseconds());
                    
                    let chartData = {
                        name: this.state.acSelected ? 'AC Current' : 'Current',
                        data: dataArr,
                        color: '#176a95',
                        type: 'line',
                        zIndex: 2,
                        pointStart: pointStartMoment,
                        pointInterval: 1000,
                        yAxis: 0
                    };
                    runDataList = runDataList.concat(chartData);

                    if (currentHCSource.furnaceData !== null && this.state.visibility.furnace) {
                        let furnArr = currentHCSource.furnaceData.map((item) => item[1]);
                        let furnData = {
                            name: 'Furnace Current',
                            data: furnArr,
                            color: 'purple',
                            type: 'line',
                            zIndex: 2,
                            pointStart: pointStartMoment,
                            pointInterval: 1000,
                            yAxis: 0
                        };
                        runDataList = runDataList.concat(furnData);
                    }

                    if (this.state.visibility.temp) {
                        if (currentHCSource.indoorTempData !== null) {
                            let indoorArr = currentHCSource.indoorTempData.map((item) => item[1]);
                            let indoorData = {
                                name: 'Indoor Hub Temp',
                                data: indoorArr,
                                color: '#d399e0',
                                type: 'line',
                                zIndex: 2,
                                pointStart: pointStartMoment,
                                pointInterval: 1000,
                                yAxis: 1
                            };
                            runDataList = runDataList.concat(indoorData);
                        }

                        if (currentHCSource.outdoorTempData !== null) {
                            let outdoorArr = currentHCSource.outdoorTempData.map((item) => item[1]);
                            let outdoorData = {
                                name: 'Outdoor Hub Temp',
                                data: outdoorArr,
                                color: '#7fc6f5',
                                type: 'line',
                                zIndex: 2,
                                pointStart: pointStartMoment,
                                pointInterval: 1000,
                                yAxis: 1
                            };
                            runDataList = runDataList.concat(outdoorData);
                        }
                    }
                }

                if (graph.highchartsDaily !== null && graph.highchartsDaily !== undefined && graph.highchartsDaily.data !== null && graph.highchartsDaily.data !== undefined && graph.highchartsDaily.data.length) {
                    let currentHCSource = graph.highchartsDaily;
                    let dataArr = currentHCSource.data.map((item) => item[1]);
                    let pointMoment = moment(currentHCSource.data[0][0]).add(moment().utcOffset() * -1, 'minutes').format("l h:mm:ss A")
                    var dt = new Date(pointMoment);
                    var pointStartMoment = Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate(), dt.getHours(), dt.getMinutes(), dt.getSeconds(), dt.getMilliseconds());

                    let chartData = {
                        name: this.state.acSelected ? 'AC Current' : 'Current',
                        data: dataArr,
                        color: '#176a95',
                        type: 'line',
                        zIndex: 2,
                        pointStart: pointStartMoment,
                        pointInterval: 1000,
                        yAxis: 0
                    };
                    timelineDataList = timelineDataList.concat(chartData);

                    if (currentHCSource.furnaceData !== null && this.state.visibility.furnace) {
                        let furnArr = currentHCSource.furnaceData.map((item) => item[1]);
                        let furnData = {
                            name: 'Furnace Current',
                            data: furnArr,
                            color: 'purple',
                            type: 'line',
                            zIndex: 2,
                            pointStart: pointStartMoment,
                            pointInterval: 1000,
                            yAxis: 0
                        };
                        timelineDataList = timelineDataList.concat(furnData);
                    }

                    if (this.state.visibility.temp) {
                        if ((currentHCSource.indoorTempData == null || currentHCSource.indoorTempData === undefined) && (currentHCSource.outdoorTempData === null || currentHCSource.outdoorTempData === undefined)) {
                            this.setState(prevState => ({
                                visibility: {
                                    ...prevState.visibility,
                                    temp: false
                                }
                            }));
                        }
                        else {
                            if (currentHCSource.indoorTempData !== null) {
                                let indoorArr = currentHCSource.indoorTempData.map((item) => item[1]);
                                let indoorData = {
                                    name: 'Indoor Hub Temp',
                                    data: indoorArr,
                                    color: '#d399e0',
                                    type: 'line',
                                    zIndex: 2,
                                    pointStart: pointStartMoment,
                                    pointInterval: 1000,
                                    yAxis: 1
                                };
                                timelineDataList = timelineDataList.concat(indoorData);
                            }

                            if (currentHCSource.outdoorTempData !== null) {
                                let outdoorArr = currentHCSource.outdoorTempData.map((item) => item[1]);
                                let outdoorData = {
                                    name: 'Outdoor Hub Temp',
                                    data: outdoorArr,
                                    color: '#7fc6f5',
                                    type: 'line',
                                    zIndex: 2,
                                    pointStart: pointStartMoment,
                                    pointInterval: 1000,
                                    yAxis: 1
                                };
                                timelineDataList = timelineDataList.concat(outdoorData);
                            }
                        }
                    }
                }
            }
            else {
                if (graph.run !== null && graph.run !== undefined && graph.run.series !== null && graph.run.series !== undefined && graph.run.series.length) {
                    for (let i = 0; i < graph.run.series.length; i++) {
                        let series = graph.run.series[i];
                        if (series.data.length) {
                            let dataArr = series.data.map((item) => (item.value));
                            let pointStart = moment(series.data[0].timestamp);
                            let data = {
                                name: series.name,
                                data: dataArr,
                                color: series.color,
                                zIndex: 2,
                                type: 'line',
                                pointStart: pointStart,
                                pointInterval: 1000,
                                yAxis: 0
                            };

                            runDataList = runDataList.concat(data);
                        }
                    }
                }

                if (graph.daily !== null && graph.daily !== undefined && graph.daily.series !== null && graph.daily.series !== undefined && graph.daily.series.length) {
                    for (let j = 0; j < graph.daily.series.length; j++) {
                        let series = graph.daily.series[j];
                        if (series.data.length) {

                            let dailyArr = series.data.map((item) => (item.value));
                            let dailyStart = moment(series.data[0].timestamp);
                            let dailyData = {
                                name: series.name,
                                data: dailyArr,
                                color: series.color,
                                zIndex: 2,
                                type: 'line',
                                pointStart: dailyStart,
                                pointInterval: 1000,
                                yAxis: 0
                            };

                            timelineDataList = timelineDataList.concat(dailyData);
                        }
                    }
                }
            }
        }

        if (multiGraph !== null && multiGraph !== undefined && multiGraph.series !== undefined && multiGraph.series.length) {
            for (let i = 0; i < multiGraph.series.length; i++) {
                let series = multiGraph.series[i];
                if (series.data.length) {

                    let multiArr = series.data.map((item) => (item.value));
                    let multiStart = moment(series.data[0].timestamp);
                    let multiEnd = moment(series.data[series.data.length - 1].timestamp);
                    let timeDiff = multiEnd.diff(multiStart);

                    let multiData = {
                        name: series.name,
                        data: multiArr,
                        color: series.color,
                        zIndex: 2,
                        type: 'line',
                        pointStart: multiStart,
                        pointInterval: timeDiff / 800,
                        yAxis: 0
                    };

                    multiDataList = multiDataList.concat(multiData);
                }
            }
        }
        
        let currentMax = this.state.currentSource !== null && this.state.currentSource !== undefined ? this.state.currentSource?.maxY : this.state.currentGraph.daily?.maxY;
        this.setState({
            runDataList: runDataList,
            timelineDataList: timelineDataList,
            multiDataList: multiDataList,
            currentMax: currentMax
        }, () => {
            let rlaMin = graph.rla - 5;
            
            if (this.state.acSelected && currentMax >= rlaMin) {
                this.setState(prevState => ({
                    visibility: {
                        ...prevState.visibility,
                        rla: true
                    }
                }));
            }
        });
    }

    getHighchartsGraphData(currentHCSource) {
        var chartDataList = [];
        var fullChartDataList = currentHCSource;
        if (fullChartDataList != null && fullChartDataList != undefined) {
            var acCurrent = fullChartDataList?.find(x => x.name == "AC Current");
            if (acCurrent != null && acCurrent != undefined) {
                chartDataList = chartDataList.concat(acCurrent);
            }


            if (this.state.visibility.temp) {
                var hasTempData = false;
                var indoorHubTemp = fullChartDataList?.find(x => x.name == "Indoor Hub Temp");
                if (indoorHubTemp != null && indoorHubTemp != undefined) {
                    chartDataList = chartDataList.concat(indoorHubTemp);
                    hasTempData = true;
                }
                var outdoorHubTemp = fullChartDataList?.find(x => x.name == "Outdoor Hub Temp");
                if (outdoorHubTemp != null && outdoorHubTemp != undefined) {
                    chartDataList = chartDataList.concat(outdoorHubTemp);
                    hasTempData = true
                }
            }
            if (this.state.visibility.furnace) {
                var furenceCurrent = fullChartDataList?.find(x => x.name == "Furnace Current");
                if (furenceCurrent != null && furenceCurrent != undefined) {
                    chartDataList = chartDataList.concat(furenceCurrent);
                }

            }
        }
        
        return chartDataList;
    }
    
    getVerify(verified) {
        var v = "";
        if (verified === true) {
            v = "Yes";
        }
        else if (verified === null) {
            v = "No";
        }
        else if (verified === false) {
            v = "False Positive";
        }
        return (v);
    }

    getStartDate(startTime) {
        var d = new Date(startTime);
        var hr = d.getHours() < 10 ? "0" : "";
        var min = d.getMinutes() < 10 ? "0" : "";
        var sec = d.getSeconds() < 10 ? "0" : "";
        var month = d.getMonth() + 1;

        var date = month + "/" + d.getDate() + "/" + d.getFullYear() + " " + d.toLocaleTimeString('en-US');

        if (startTime == "" || startTime == null)
            date = "";

        return (date);
    }

    getEndDate(endTime) {
        var d = new Date(endTime);
        var hr = d.getHours() < 10 ? "0" : "";
        var min = d.getMinutes() < 10 ? "0" : "";
        var sec = d.getSeconds() < 10 ? "0" : "";
        var month = d.getMonth() + 1;

        var date = month + "/" + d.getDate() + "/" + d.getFullYear() + " " + d.toLocaleTimeString('en-US');

        if (endTime == "" || endTime == null) {
            date = "Active";
            return (<div style={{ color: 'red' }}>{date}</div>);
        }
        else {
            return (date);
        }
    }

    getAddress() {
        var addy = "";

        if (this.state.currentCustomer.address !== "" && this.state.currentCustomer.address !== null) {
            addy = this.state.currentCustomer.address;

            if (this.state.currentCustomer.city !== "" && this.state.currentCustomer.city !== null) {
                addy += ", " + this.state.currentCustomer.city;
            }

            if (this.state.currentCustomer.state !== "" && this.state.currentCustomer.state !== null) {
                addy += ", " + this.state.currentCustomer.state;

                if (this.state.currentCustomer.zip !== "" && this.state.currentCustomer.zip !== null)
                    addy += " " + this.state.currentCustomer.zip;
            }
        }
        return (addy);
    }

    getPropertyValueRaw(propertyName) {
        if (this.state.currentAsset.properties) {
            let result = this.state.currentAsset.properties.find((i) => i.name == propertyName);
            return result ? result["valueRaw"] : null;
        }
    }

    showZoomButton(show) {
        this.setState({ showZoomButton: show });
    }

    getHighchartsAnnotations(graphAnnotations) {
        var plotBands = [];

        if (graphAnnotations !== null && graphAnnotations !== undefined && graphAnnotations.length) {
            for (let i = 0; i < graphAnnotations.length; i++) {
                let annotation = graphAnnotations[i];
                let bandStart = moment(annotation["startTime"]);
                let bandEnd = moment(annotation["endTime"]);

                let band = {
                    color: annotation["color"] + "45", /* opacity */
                    from: bandStart,
                    to: bandEnd,
                    label: {
                        text: annotation["state"],
                        rotation: -90,
                        textAlign: 'left',
                        verticalAlign: 'top',
                        y: 90
                    },
                    zIndex: 1
                };

                plotBands = plotBands.concat(band);
            }
        }

        return plotBands;
    }

    renderTable(alertType) {
        const { classes } = this.props;
        let table = null;

        let alertLoader = this.props.alertTableLoading ?
            <div className={["loading", classes.root].join(' ')} style={{ display: "block" }}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : <div>{null}</div>;

        let activeContent = this.props.alertList.length ?
            <tbody>
                {this.props.alertList.map((alert, index) =>
                    <tr key={alert.alertId} data={index} className={this.state.isSelected === index ? "tableSelected" : ""}>
                        <td onClick={() => { this.onAlertClick(index) }}>{alert.priorityName}</td>
                        <td onClick={() => { this.onAlertClick(index) }}>{alert.message}</td>
                        <td onClick={() => { this.onAlertClick(index) }}>{this.getStartDate(alert.startTime)}</td>
                        <td onClick={() => { this.onAlertClick(index) }}>{this.getEndDate(alert.endTime)}</td>
                        <td onClick={() => { this.onAlertClick(index) }}>{this.getVerify(alert.isVerified)}</td>
                    </tr>
                )}
            </tbody>
            : <tbody>
                <tr><td>{this.props.alertTableLoading ? "Loading Active Alerts..." : "No Active Alerts"}</td></tr>
            </tbody>;

        let inactiveContent = this.props.alertList.length ?
            <tbody>
                {this.props.alertList.map((alert, index) =>
                    <tr key={alert.alertId} data={index} className={this.state.isSelected === index ? "tableSelected" : ""}>
                        <td onClick={() => { this.onAlertClick(index) }}>{alert.priorityName}</td>
                        <td onClick={() => { this.onAlertClick(index) }}>{alert.message}</td>
                        <td onClick={() => { this.onAlertClick(index) }}>{this.getStartDate(alert.startTime)}</td>
                        <td onClick={() => { this.onAlertClick(index) }}>{this.getEndDate(alert.endTime)}</td>
                        <td onClick={() => { this.onAlertClick(index) }}>{this.getVerify(alert.isVerified)}</td>
                    </tr>
                )}
            </tbody>
            : <tbody>
                <tr><td>{this.props.alertTableLoading ? "Loading Intermittent Alerts..." : "No Intermittent Alerts"}</td></tr>
            </tbody>;

        let closedContent = this.props.alertList.length ?
            <tbody>
                {this.props.alertList.map((alert, index) =>
                    <tr key={alert.alertId} data={index} className={this.state.isSelected === index ? "tableSelected" : ""}>
                        <td onClick={() => { this.onAlertClick(index) }}>{alert.priorityName}</td>
                        <td onClick={() => { this.onAlertClick(index) }}>{alert.message}</td>
                        <td onClick={() => { this.onAlertClick(index) }}>{this.getStartDate(alert.startTime)}</td>
                        <td onClick={() => { this.onAlertClick(index) }}>{this.getEndDate(alert.endTime)}</td>
                        <td onClick={() => { this.onAlertClick(index) }}>{this.getVerify(alert.isVerified)}</td>
                        <td onClick={() => { this.onAlertClick(index) }}>{this.getStartDate(alert.closedOn)}</td>
                    </tr>
                )}
            </tbody>
            : <tbody>
                <tr><td>{this.props.alertTableLoading ? "Loading Closed Alerts..." : "No Closed Alerts"}</td></tr>
            </tbody>;

        let repairsContent = this.props.alertList.length ?
            <tbody>
                {this.props.alertList.map((alert, index) =>
                    <tr key={alert.alertId} data={index} className={this.state.isSelected === index ? "tableSelected" : ""}>
                        <td onClick={() => { this.onAlertClick(index) }}>{alert.priorityName}</td>
                        <td onClick={() => { this.onAlertClick(index) }}>{alert.message}</td>
                        <td onClick={() => { this.onAlertClick(index) }}>{this.getStartDate(alert.startTime)}</td>
                        <td onClick={() => { this.onAlertClick(index) }}>{this.getEndDate(alert.endTime)}</td>
                        <td onClick={() => { this.onAlertClick(index) }}>{this.getVerify(alert.isVerified)}</td>
                    </tr>
                )}
            </tbody>
            : <tbody>
                <tr><td>{this.props.alertTableLoading ? "Loading Repairs Alerts..." : "No Repairs Alerts"}</td></tr>
            </tbody>;

        let deviceContent = this.props.alertList.length ?
            <tbody>
                {this.props.alertList.map((alert, index) =>
                    <tr key={alert.alertId} data={index} className={this.state.isSelected === index ? "tableSelected" : ""}>
                        <td onClick={() => { this.onAlertClick(index) }}>{alert.priorityName}</td>
                        <td onClick={() => { this.onAlertClick(index) }}>{alert.message}</td>
                        <td onClick={() => { this.onAlertClick(index) }}>{this.getStartDate(alert.startTime)}</td>
                        <td onClick={() => { this.onAlertClick(index) }}>{this.getEndDate(alert.endTime)}</td>
                        <td onClick={() => { this.onAlertClick(index) }}>{this.getVerify(alert.isVerified)}</td>
                    </tr>
                )}
            </tbody>
            : <tbody>
                <tr><td>{this.props.alertTableLoading ? "Loading Device Alerts..." : "No Device Alerts"}</td></tr>
            </tbody>;

        switch (alertType) {
            case "Active":
                table =
                    <div style={{ maxHeight: "300px", marginBottom: '15px', overflow: "auto" }}>
                        <table className='table table-striped table-bordered table-sm table-hover' aria-labelledby="tabelLabel">
                            <thead>
                                <tr >
                                    <th>Priority</th>
                                    <th>Alert Message</th>
                                    <th>Start</th>
                                    <th>End</th>
                                    <th>Verified</th>
                                </tr>
                            </thead>
                            {activeContent}
                        </table>
                        {alertLoader}
                    </div>;
                break;

            case "Inactive":
                table =
                    <div style={{ maxHeight: "300px", marginBottom: '15px', overflow: "auto" }}>
                        <table className='table table-striped table-bordered table-sm table-hover' aria-labelledby="tabelLabel">
                            <thead>
                                <tr >
                                    <th>Priority</th>
                                    <th>Alert Message</th>
                                    <th>Start</th>
                                    <th>End</th>
                                    <th>Verified</th>
                                </tr>
                            </thead>
                            {inactiveContent}
                        </table>
                        {alertLoader}
                    </div>;
                break;

            case "Closed":
                table =
                    <div style={{ maxHeight: "300px", marginBottom: '15px', overflow: "auto" }}>
                        <table className='table table-striped table-bordered table-sm table-hover' aria-labelledby="tabelLabel">
                            <thead>
                                <tr >
                                    <th>Priority</th>
                                    <th>Alert Message</th>
                                    <th>Start</th>
                                    <th>End</th>
                                    <th>Verified</th>
                                    <th>Closed</th>
                                </tr>
                            </thead>
                            {closedContent}
                        </table>
                        {alertLoader}
                    </div>;
                break;

            case "Repairs":
                table =
                    <div style={{ maxHeight: "300px", marginBottom: "15px", overflow: "auto" }}>
                        <table className='table table-striped table-bordered table-sm table-hover' aria-labelledby="tabelLabel">
                            <thead>
                                <tr >
                                    <th>Priority</th>
                                    <th>Alert Message</th>
                                    <th>Start</th>
                                    <th>End</th>
                                    <th>Verified</th>
                                </tr>
                            </thead>
                            {repairsContent}
                        </table>
                        {alertLoader}
                    </div>;
                break;

            case "Device":
                table =
                    <div style={{ maxHeight: "300px", marginBottom: "15px", overflow: "auto" }}>
                        <table className='table table-striped table-bordered table-sm table-hover' aria-labelledby="tabelLabel">
                            <thead>
                                <tr >
                                    <th>Priority</th>
                                    <th>Alert Message</th>
                                    <th>Start</th>
                                    <th>End</th>
                                    <th>Verified</th>
                                </tr>
                            </thead>
                            {deviceContent}
                        </table>
                        {alertLoader}
                    </div>;
                break;
        }

        return table;
    }

    renderAlerts() {
        const { classes } = this.props;

        let alertDetailLoader = this.state.alertDetailLoading ?
            <div className={["loading", classes.root].join(' ')} style={{ display: "block" }}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : <div>{null}</div>;

        let alertTable = this.renderTable(this.state.alertType);

        let jobDetailBtn = this.state.alertType == "Repairs" ?
            <button hidden={this.state.isSelected === -1} type="button" className="secondary-btn btn-small" onClick={() => { Event("View Job Details Clicked", "User clicked the view job details button", "View Job Details Clicked"); this.openJobDetailModal(this.state.currentAlert); }}>View Job Details</button>
            : null;

        let devicesBtn = this.state.alertType == "Device" ?
            <button hidden={this.state.isSelected === -1} type="button" className="secondary-btn btn-small" onClick={() => { Event("View Devices Clicked", "User clicked the view devices button", "View Devices Clicked"); this.openDevicesModal(this.state.currentAlert); }}>View Devices</button>
            : null;

        let alertButtons = this.props.showButtons ?
            <div className="btn-right">
                {jobDetailBtn}
                {devicesBtn}
                <button hidden={this.state.isSelected === -1} type="button" className="secondary-btn btn-small" onClick={() => { Event("Edit Alert Clicked", "User clicked edit alert button", "Edit Alert Clicked"); this.openEditAlertModal(this.state.currentAlert); }}>Edit Alert</button>
                <button hidden={this.state.isSelected === -1} type="button" className="secondary-btn btn-small" onClick={() => { Event("Create Job Clicked", "User clicked create job button", "Create Job Clicked"); this.openCreateJobModal(this.state.currentAlert, this.state.currentOpportunity); }}>Create Job</button>
                <button hidden={this.state.isSelected === -1} type="button" className="secondary-btn btn-small" onClick={() => { Event("Close Alert Clicked", "User clicked close alert button", "Close Alert Clicked"); this.openCloseAlertModal(this.state.currentAlert); }}>Close Alert</button>
            </div>
            : <div>{null}</div>;

        return (
            <div>
                <div className="row no-gutters" style={{ display: 'flex' }}>
                    <div className="col-sm-12 col-md-8">
                        
                        <div className="white-container" style={{ minHeight: '100%' }}>
                            <h5 className="sectionTitle">Alerts</h5>
                            <div className="radio-container" onChange={e => this.changeRadio(e)} >
                                <span>
                                    <input type="radio" value="Active" defaultChecked name="alertType" />
                                    &nbsp; Active &nbsp;&nbsp;
                                <input type="radio" value="Inactive" name="alertType" />
                                    &nbsp; Intermittent &nbsp;&nbsp;
                                <input type="radio" value="Closed" name="alertType" />
                                    &nbsp; Closed &nbsp;&nbsp;
                                <input type="radio" value="Repairs" name="alertType" />
                                    &nbsp; Repairs &nbsp;&nbsp;
                                <input type="radio" value="Device" name="alertType" />
                                    &nbsp; Device &nbsp;&nbsp;
                            </span>
                            </div>
                            <div className="table-container">
                                {alertTable}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4">
                        
                        <div className="grey-container" style={{ minHeight: 'calc(100% - 10px)' }}>
                            <h5 className="sectionTitle">Alert Details</h5>
                            <small>
                                <div className="detailTitle">Customer Information</div>
                                <div className="row no-gutters">
                                    <div className="col-5">
                                        <p className="semibold">Customer:</p>
                                    </div>
                                    <div className="col">
                                        <p>{this.state.currentCustomer.name}</p>
                                    </div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-5">
                                        <p className="semibold">Phone:</p>
                                    </div>
                                    <div className="col">
                                        <p>{this.state.currentCustomer.phone}</p>
                                    </div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-5">
                                        <p className="semibold">Email:</p>
                                    </div>
                                    <div className="col">
                                        <p>{this.state.currentCustomer.email}</p>
                                    </div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-5">
                                        <p className="semibold">Address:</p>
                                    </div>
                                    <div className="col">
                                        {this.getAddress()}
                                    </div>
                                </div>
                                <hr />
                                <div className="detailTitle" >Asset Information</div>
                                <div className="row no-gutters">
                                    <div className="col-5">
                                        <p className="semibold">Model:</p>
                                    </div>
                                    <div className="col">
                                        <p>{this.state.currentAsset.model}</p>
                                    </div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-5">
                                        <p className="semibold">Make:</p>
                                    </div>
                                    <div className="col">
                                        <p>{this.state.currentAsset.make}</p>
                                    </div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-5">
                                        <p className="semibold">Manufacturing Date:</p>
                                    </div>
                                    <div className="col">
                                        {this.state.currentAsset.mfgDate}
                                    </div>
                                </div>
                                <div className="row no-gutters lastItem">
                                    <div className="col-10">
                                        <br />
                                    </div>
                                    <div className="col">
                                        {alertButtons}
                                    </div>
                                </div>
                            </small>
                        </div>
                    </div>
                </div>
                {alertDetailLoader}
            </div>
        );
    }

    renderACGraph(isRunPanel) {
        let acRunPanel = this.state.currentSource !== null && this.state.currentSource !== undefined && this.state.currentGraph !== undefined && this.state.currentGraph.timeSeriesDataSources !== undefined && this.state.currentGraph.timeSeriesDataSources.length ?
            <div className="row" style={{ marginTop: '10px' }}>
                <div className="col-sm-12 col-md-8">
                    <div className="highlight-bar-charts" >
                        <HighchartGraph graphType={"ASSET"} chartData={this.getHighchartsGraphData(this.state.runDataList)} key={`${this.state.currentAsset.assetId}-${this.state.currentSource.timeString}-${this.state.runDataList.length}-${this.state.visibility}`} isAirConditioner={true} dataMax={this.state.currentSource.maxY}
                            showRLA={this.state.visibility.rla} rlaValue={this.state.currentGraph.rla} showLRA={this.state.visibility.lra} lraValue={this.state.currentGraph.lra} showFLA={false} flaValue={null} graphAnnotations={this.getHighchartsAnnotations(this.state.currentSource.graphAnnotations)} showAnnotations={this.state.showRunAnnotations}
                            showAnalytics={this.state.visibility.analytics} hasAnalytics={this.state.currentAsset.hasAnalytics} showFurnace={this.state.visibility.furnace} showTemp={this.state.visibility.temp} timeString={this.state.currentSource.timeString /*currentGraph.highchartsRun.name*/}
                            pointStart={this.state.currentGraph.highchartsRun !== null && this.state.currentGraph.highchartsRun !== undefined && this.state.currentGraph.highchartsRun.data !== null && this.state.currentGraph.highchartsRun.data.length ? this.state.currentGraph.highchartsRun?.data[0] : this.state.currentGraph.highchartsDataSources[0]?.data[0]} hasTempAxis={true} />
                        {/*<HighchartGraph graphType={"ASSET"} chartData={this.getHighchartsGraphData(this.state.runDataList)} key={`${this.state.currentAsset.assetId}-${this.state.currentSource?.timeString}-${this.state.runData?.length}-${this.state.visibility}`} isAirConditioner={true} dataMax={this.state.currentGraph.daily.maxY}
                            showRLA={this.state.visibility.rla} rlaValue={this.state.currentGraph.daily.dataArray[0]["RLA"]} showLRA={this.state.visibility.lra} lraValue={this.state.currentGraph.daily.dataArray[0]["LRA"]} showFLA={false} flaValue={null} graphAnnotations={this.getHighchartsAnnotations(this.state.currentSource.graphAnnotations)}
                            showAnalytics={this.state.visibility.analytics} hasAnalytics={this.state.currentAsset.hasAnalytics} showFurnace={this.state.visibility.furnace} showTemp={this.state.visibility.temp} timeString={this.state.currentSource?.timeString} pointStart={this.state.currentGraph.highchartsRun.data[0]} />*/}

                        <br /><br />
                    </div>
                </div>
                <div className="col-sm-12 col-md-4">
                    <ACRightPanel onClick={this.openFullRunModal} isFullScreen={false} isDaily={false} showAnnotations={this.state.showRunAnnotations} onClick1={this.runAnalytic.bind(this)} onClick2={() => { this.child.zoomOut(); }} visibility={this.state.visibility}
                        handleCheckbox={this.handleCheckbox} zoomOutEnabled={this.state.showZoomButton} hasAnalytics={this.state.currentAsset.hasAnalytics == true ? true : false} canLeftClick={this.state.canLeftClick == true ? true : false} canRightClick={this.state.canRightClick == true ? true : false}
                        runNamesList={this.state.currentGraph.runNamesList} selectedRun={this.state.selectedRun} onChangeRun={this.onChangeRun} onLeftRunClick={this.onLeftRunClick} onRightRunClick={this.onRightRunClick} />
                </div>
            </div>
            : <div className="centered"><br />No Data found!</div>;

        let acDailyPanel = this.state.currentGraph !== undefined && this.state.currentGraph.daily !== null && this.state.currentGraph.daily !== undefined && this.state.currentGraph.daily.dataArray.length ?
            <div className="row" style={{ marginTop: '10px' }}>
                <div className="col-sm-12 col-md-8">
                    <div className="highlight-bar-charts" >
                        <HighchartGraph graphType={"ASSET"} chartData={this.state.timelineDataList} key={`${this.state.currentGraph.highchartsDaily.name}-${this.state.visibility}-timeline`} isAirConditioner={true} dataMax={this.state.currentGraph.daily?.maxY}
                             showRLA={this.state.visibility.rla} rlaValue={this.state.currentGraph.rla} showLRA={this.state.visibility.lra} lraValue={this.state.currentGraph.lra} showFLA={false} flaValue={null} graphAnnotations={this.getHighchartsAnnotations(this.state.currentGraph.daily.graphAnnotations)} showAnnotations={this.state.showRunAnnotations}
                             showAnalytics={this.state.visibility.analytics} hasAnalytics={this.state.currentAsset.hasAnalytics} showFurnace={this.state.visibility.furnace} showTemp={this.state.visibility.temp} timeString={this.state.currentGraph.highchartsRun.name}
                             pointStart={this.state.currentGraph.highchartsDaily.data[0]} hasTempAxis={true} />

                        <br /><br />
                    </div>
                </div>
                <div className="col-sm-12 col-md-4">
                    <ACRightPanel onClick={this.openFullDailyModal} isFullScreen={false} isDaily={true} showAnnotations={this.state.showRunAnnotations} onClick1={this.runAnalytic.bind(this)} onClick2={() => { this.child.zoomOut(); }} 
                        handleCheckbox={this.handleCheckbox} visibility={this.state.visibility} zoomOutEnabled={this.state.showZoomButton} hasAnalytics={this.state.currentAsset.hasAnalytics == true ? true : false} />
                </div>
            </div>
            : <div className="centered"><br />No Data found!</div>;

        let acPanel = isRunPanel ? acRunPanel : acDailyPanel;

        return (acPanel);
    }
    
    renderGraph() {
        let multiGraph = this.state.multiseriesGraph !== null && this.state.multiseriesGraph !== undefined && this.state.multiseriesGraph.series !== null && this.state.multiseriesGraph.series !== undefined && this.state.multiseriesGraph.series[0] !== undefined && this.state.multiseriesGraph.series[0] !== null ?
            <div>
                <HighchartGraph graphType={"MULTISERIES"} chartData={this.state.multiDataList} key={`Multiseries-${this.state.multiseriesGraph.series.length}`} graph={this.state.multiseriesGraph} isAirConditioner={true} dataMax={this.state.multiseriesGraph.yDataMax}
                    showRLA={false} rlaValue={this.state.currentGraph.rla} showLRA={false} lraValue={this.state.currentGraph.lra} showFLA={false} flaValue={null} graphAnnotations={this.getHighchartsAnnotations(this.state.multiseriesGraph.graphAnnotations)}
                    showAnalytics={this.state.visibility.analytics} hasAnalytics={this.state.hasAnalytics} showFurnace={this.state.visibility.furnace} showTemp={this.state.visibility.temp} timeString={null} pointStart={this.state.multiseriesGraph.series[0].data[0]?.timestamp} />
            </div>
            : <div className="centered"><br/>No Data found!</div>;

        let runPanel = this.state.acGraph ?
            <div>{this.renderACGraph(true)}</div>
            : (this.state.currentSource !== null && this.state.currentSource !== undefined && this.state.currentGraph.run !== null && this.state.currentGraph.run !== undefined && this.state.currentGraph.run.series !== null && this.state.currentGraph.run.series !== undefined && this.state.currentGraph.run.series[0] !== null && this.state.currentGraph.run.series[0] !== undefined ?
                <div className="row" style={{ marginTop: '10px' }}>
                    <div className="col-sm-12 col-md-10">
                        <div className="highlight-bar-charts" >
                            <HighchartGraph graphType={"ASSET"} chartData={this.state.runDataList} key={`${this.state.currentSource?.timeString}-${this.state.runDataList.length}-${this.state.visibility}`} isAirConditioner={false} dataMax={this.state.currentGraph.run.yDataMax}
                                showRLA={this.state.visibility.rla} rlaValue={this.state.currentGraph.rla} showLRA={this.state.visibility.lra} lraValue={this.state.currentGraph.lra} showFLA={false} flaValue={null} graphAnnotations={this.getHighchartsAnnotations(this.state.currentSource.graphAnnotations)}
                                showAnalytics={this.state.visibility.analytics} hasAnalytics={this.state.hasAnalytics} showFurnace={this.state.visibility.furnace} showTemp={this.state.visibility.temp} timeString={this.state.currentSource?.timeString} pointStart={this.state.currentGraph.run.series[0].data[0]?.timestamp} />
                            <br /><br />
                        </div>
                    </div>
                    <div className="col-sm-4 col-md-2" style={{ marginTop: "25px" }}>
                        <RunRightPanel onClick={this.openFullRunModal} isFullScreen={false} showAnnotations={this.state.showRunAnnotations}
                            onClick1={this.runAnalytic.bind(this)} onClick2={() => { this.child.zoomOut(); }}
                            zoomOutEnabled={this.state.showZoomButton} hasAnalytics={this.state.currentAsset.hasAnalytics == true ? true : false} />
                    </div>
                </div>
                : <div className="centered"><br />No Data found!</div>);

        let dailyPanel = this.state.acGraph ?
            <div>{this.renderACGraph(false)}</div>
            : (this.state.currentGraph.daily !== null && this.state.currentGraph.daily !== undefined && this.state.currentGraph.daily.series !== null && this.state.currentGraph.daily.series !== undefined && this.state.currentGraph.daily.series[0] !== null && this.state.currentGraph.daily.series !== undefined ?
                <div className="row" style={{ marginTop: '10px' }}>
                    <div className="col-sm-12 col-md-8">
                        <HighchartGraph graphType={"ASSET"} chartData={this.state.timelineDataList} key={`${this.state.currentGraph.daily?.timeString}-${this.state.visibility}`} isAirConditioner={false} dataMax={this.state.currentGraph.daily.yDataMax}
                            showRLA={this.state.visibility.rla} rlaValue={this.state.currentGraph.rla} showLRA={this.state.visibility.lra} lraValue={this.state.currentGraph.lra} showFLA={false} flaValue={null} graphAnnotations={this.getHighchartsAnnotations(this.state.currentGraph.daily.graphAnnotations)}
                            showAnalytics={this.state.visibility.analytics} hasAnalytics={this.state.hasAnalytics} showFurnace={this.state.visibility.furnace} showTemp={this.state.visibility.temp} timeString={this.state.currentGraph.daily?.timeString} 
                            pointStart={this.state.currentGraph.daily.series[0].data[0]?.timestamp} />

                        <br /><br />
                    </div>
                    <div className="col-sm-12 col-md-4">
                        <TimeRightPanel selected={this.state.dpStartDateTime} onChange={date => {
                            Event("Run Start Date Click", "User clicked start date picker to change it", "Run Start Date Changed");
                            this.onChangeStart(date);
                        }} selected1={this.state.dpEndDateTime} onChange1={date => {
                            Event("Run End Date Click", "User clicked end date picker to change it", "Run End Date Changed");
                            this.onChangeEnd(date);
                        }} onClick={this.onViewGraph} onClick1={this.openFullDailyModal}
                            showZoomButton={this.state.showZoomButton} showAnalyticsButton={true} onClick2={() => { this.child.zoomOut(); }}
                            onClick3={this.runAnalytic.bind(this)} showAnnotations={this.state.showRunAnnotations} hasAnalytics={this.state.currentAsset.hasAnalytics == true ? true : false} />
                        <br /><br />
                    </div>
                </div>
                : <div className="centered"><br />No Data found!</div>);

        let noDataStr = this.state.alertSelected ? "No Data Found!" : "Select an alert to display graph here";

        if (this.state.multiseriesGraph !== undefined && this.state.multiseriesGraph !== null && this.state.multiseriesGraph.series !== null && this.state.multiseriesGraph.series !== undefined ) {
            return (
                <div style={{marginRight: 25 + "px"}}>
                    <Tabs selectedIndex={this.state.tabIndex} onSelect={index => this.setState({ tabIndex: index })}>
                        <TabList style={{ paddingLeft: 15 + "px" }}>
                            <Tab>Run</Tab>
                            <Tab>Trend</Tab>
                        </TabList>

                        <TabPanel>
                            {runPanel}
                        </TabPanel>
                        <TabPanel>
                            <div className="row">
                                <div className="col-sm-12 col-md-8">
                                    <div style={{marginTop: 10 + "px", marginRight: 15 + "px"}}>
                                        {multiGraph}
                                        <br/><br/>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-4">
                                    <TimeRightPanel selected={this.state.dpStartDateTime} onChange={date => {
                                        Event("Run Start Date Click", "User clicked start date picker to change it", "Run Start Date Changed");
                                        this.onChangeStart(date);
                                    }} selected1={this.state.dpEndDateTime} onChange1={date => {
                                        Event("Run End Date Click", "User clicked end date picker to change it", "Run End Date Changed");
                                        this.onChangeEnd(date);
                                    }} onClick={this.onViewGraph} onClick1={this.openFullMultiModal}
                                        showZoomButton={this.state.showZoomButton} haAnalytics={this.state.currentAsset.hasAnalytics} onClick2={() => {
                                        this.child.zoomOut();
                                    }}/>
                                    <br/><br/>
                                </div>
                            </div>

                        </TabPanel>
                    </Tabs>
                </div>
            );
        }
        else if (Object.keys(this.state.currentGraph).length && !this.state.alertDetailLoading && this.state.currentGraph !== undefined && this.state.currentGraph !== null && ((this.state.acSelected ? this.state.currentSource !== null : this.state.currentGraph.run !== null) || this.state.currentGraph.daily !== null)) {
            return (
                <div style={{ marginRight: 25 + "px" }}>
                    <Tabs selectedIndex={this.state.tabIndex} onSelect={index => this.setState({ tabIndex: index }) }>
                        <TabList style={{ paddingLeft: 15 + "px" }}>
                            <Tab>Run</Tab>
                            <Tab>Timeline</Tab>
                        </TabList>
                            
                        <TabPanel>
                            {runPanel}
                        </TabPanel>

                        <TabPanel>
                            {dailyPanel}
                        </TabPanel>
                    </Tabs>
                </div>
            );
        }
        
        else { 
            return (
                <div style={{ marginTop: 15 + "px", marginRight: 25 + "px" }}>
                    <Tabs selectedIndex={this.state.tabIndex} onSelect={index => this.setState({ tabIndex: index })}>
                        <TabList style={{ paddingLeft: 15 + "px" }}>
                            <Tab>Run</Tab>
                            <Tab>Timeline</Tab>
                            <Tab>Trend</Tab>
                        </TabList>

                        <TabPanel>
                            <br />
                            <div className="centered">
                                {noDataStr}
                            </div>
                            <br /><br />
                        </TabPanel>
                        <TabPanel>
                            <br />
                            <div className="centered">
                                {noDataStr}
                            </div>
                            <br /><br />
                        </TabPanel>
                        <TabPanel>
                            <br />
                            <div className="centered">
                                {noDataStr}
                            </div>
                            <br /><br />
                        </TabPanel>
                    </Tabs>
                </div>
            );
        }
    }

    renderContent() {
        return (
            <div>
                {this.renderAlerts()}
                <p className="status-msg">{this.state.message}</p>
                {this.state.alertType != "Device" ? this.renderGraph() : null}
            </div>
        );
    }

    renderTabTable() {
        return (
            <div style={{ height: '200px', margin: '0 20px 20px', border: '1px solid whitesmoke', borderRadius: '.15rem' }}>

                <div style={{ overflow: "auto" }}>
                    <table className='table table-hover' aria-labelledby="tabelLabel">
                        <thead>
                            <tr>
                                <th>Severity</th>
                                <th>Alert</th>
                                <th>Problem</th>
                                <th>Start</th>
                                <th>End</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            {this.props.alertList.map((alert, index) =>
                                <tr key={alert.alertId} data={index} className={this.state.isSelected === index ? "tableSelected" : ""}>
                                    <td onClick={() => { this.onAlertClick(index) }}>{alert.priorityName}</td>
                                    <td onClick={() => { this.onAlertClick(index) }}>{alert.type}</td>
                                    <td onClick={() => { this.onAlertClick(index) }}>Show problem msg from database</td>
                                    <td onClick={() => { this.onAlertClick(index) }}>{this.getStartDate(alert.startTime)}</td>
                                    <td onClick={() => { this.onAlertClick(index) }}>{this.getEndDate(alert.endTime)}</td>
                                    <td><ArrowDownIcon className="db-blue db-blue-bg" style={{ height: '20px', width: '20px', borderRadius: '10px' }} /></td>
                                </tr>
                            )}
                        </tbody>

                    </table>
                </div>
            </div>
        );
    }

    renderNewContent() {
        return (
            <div>
                <div className="row no-gutters alerts-tab-wrapper" style={{ margin: '20px 30px', border: '1px solid whitesmoke', borderRadius: '.1rem', boxShadow: 'inset 0 -1px 0 rgba(100,121,143,0.122)' }}>
                    <div onClick={() => this.onTableTabClick(0)}>

                        <Card elevation={0}>
                            <CardContent style={{ padding: 'unset' }}>
                                <div className="alerts-tab-item">
                                    <div className="alerts-tab-label">
                                        <NotificationIcon className="alerts-tab-icon" />
                                            Equipment Health
                                            <div className="alerts-badge db-red-bg2">{this.props.partnerInfo.criticalCount} Critical</div>
                                            <div className="alerts-badge db-orange-bg2">{this.props.partnerInfo.warningCount} Warning</div>
                                        <div className="alerts-badge" style={{ backgroundColor: '#ff69b6a6' }}>{this.props.partnerInfo.scheduledCount} Scheduled</div>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                        <div className={this.state.tableIndex == 0 ? "alerts-indicator db-blue-bg2" : 'alerts-indicator'}/>
                    </div>

                    <div onClick={() => this.onTableTabClick(1)}>
                        <Card elevation={0}>
                            <CardContent style={{ padding: 'unset' }}>

                                <div className="alerts-tab-item">
                                    <div className="alerts-tab-label">
                                        <SignalIcon className="alerts-tab-icon" />
                                            Device Health
                                            <div className="alerts-badge db-teal-bg2">{this.props.partnerInfo.downCount} Critical</div>
                                            <div className="alerts-badge db-purple-bg2">{this.props.partnerInfo.lowSignalCount} Warning</div>
                                    </div>
                                </div>

                            </CardContent>
                        </Card>
                        <div className={this.state.tableIndex == 1 ? "alerts-indicator db-blue-bg2" : 'alerts-indicator'} />
                    </div>
                </div>

                <div className="alerts-tab-wrapper" style={{ margin: '0 30px', border: '1px solid whitesmoke', padding: '10px 0'}}>
                    <div className="alerts-tab-label" style={{ paddingLeft: '45px' }}>Table Options</div>
                    <div onChange={e => this.changeTableRadio(e)} style={{ paddingLeft: '40px', margin: '5px' }}>
                        <div style={{ height: '24px', display: 'flex', alignItems: 'center' }}>
                            <input type="radio" value="Critical" defaultChecked name="alertTableType" />
                                    &nbsp; Critical &nbsp;&nbsp;
                                <input type="radio" value="Warning" name="alertTableType" />
                                    &nbsp; Warning &nbsp;&nbsp;
                                <input type="radio" value="Scheduled" name="alertTableType" />
                                    &nbsp; Scheduled &nbsp;&nbsp;
                                <input type="radio" value="All" name="alertTableType" />
                                    &nbsp; All &nbsp;&nbsp;
                                <IconTooltip title="Filter" enterDelay={600} leaveDelay={200}>
                                    <IconButton 
                                        aria-label="Filter Table"
                                        aria-controls="menu-filter"
                                        aria-haspopup="true"
                                        onClick={this.handleFilter}
                                        style={{ padding: '2px', borderRadius: '3px' }}
                                    >
                                        <FilterIcon className="db-blue db-blue-bg" style={{ borderRadius: '3px', height: '22px', width: '22px', padding: '2px' }} />
                                    </IconButton>
                                </IconTooltip>
                            </div>
                    </div>
                </div>

                <div>
                    <TriageAlertsTable alertList={this.props.alertList} alertMessaging={this.props.alertMessaging} currentAsset={this.state.currentAsset} currentCustomer={this.state.currentCustomer} onAlertClick={this.onAlertClick} alertSelected={this.state.alertSelected} />
                </div>
            </div>
        );
    }

    render() {
        const { classes } = this.props;
        
        let contents = this.props.loading ?
            <div className={["loading", classes.root].join(' ')} style={{ display: "block" }}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : this.renderContent();

        let runGraph = this.state.currentGraph !== undefined && this.state.currentGraph !== null ?
            this.state.acGraph && this.state.currentSource !== undefined && this.state.currentSource !== null && this.state.currentGraph.timeSeriesDataSources.length && this.state.currentGraph.run !== undefined && this.state.currentGraph.run !== null && this.state.currentGraph.run.series !== undefined && this.state.currentGraph.run.series[0] !== null && this.state.currentGraph.run.series[0] !== undefined ?
                <HighchartGraph graphType={"ASSET"} chartData={this.state.runDataList} key={`${this.state.currentAsset.assetId}-${this.state.currentSource?.timeString}-${this.state.runData?.length}-${this.state.visibility}-fullscreen`} isAirConditioner={true} dataMax={this.state.currentGraph.run.yDataMax}
                    showRLA={this.state.visibility.rla} rlaValue={this.state.currentGraph.daily.dataArray[0]["RLA"]} showLRA={this.state.visibility.lra} lraValue={this.state.currentGraph.daily.dataArray[0]["LRA"]} showFLA={false} flaValue={null} graphAnnotations={this.getHighchartsAnnotations(this.state.currentSource.graphAnnotations)}
                    showAnalytics={this.state.visibility.analytics} hasAnalytics={this.state.currentAsset.hasAnalytics} showFurnace={this.state.visibility.furnace} showTemp={this.state.visibility.temp} timeString={this.state.currentSource?.timeString} pointStart={this.state.currentGraph.run.series[0].data[0]?.timestamp} />
                : (this.state.currentGraph.run !== undefined && this.state.currentGraph.run !== null && this.state.currentGraph.run.series !== undefined && this.state.currentGraph.run.series[0] !== null && this.state.currentGraph.run.series[0] !== undefined ?
                    <HighchartGraph graphType={"ASSET"} chartData={this.state.runDataList} key={`${this.state.currentSource?.timeString}-${this.state.runDataList?.length}-${this.state.visibility}-fullscreen`} isAirConditioner={false} dataMax={this.state.currentGraph.run.yDataMax}
                    showRLA={this.state.visibility.rla} rlaValue={this.state.currentGraph.rla} showLRA={this.state.visibility.lra} lraValue={this.state.currentGraph.lra} showFLA={false} flaValue={null} graphAnnotations={this.getHighchartsAnnotations(this.state.currentGraph.run.graphAnnotations)}
                    showAnalytics={this.state.visibility.analytics} hasAnalytics={this.state.hasAnalytics} showFurnace={this.state.visibility.furnace} showTemp={this.state.visibility.temp} timeString={this.state.currentSource?.timeString} pointStart={this.state.currentGraph.run.series[0].data[0]?.timestamp} />
                    : <div></div>)
            : <div></div>;

        let fullScreenRunModal = this.state.currentGraph !== undefined && this.state.currentGraph !== null ?
            <FullScreenChartModal show={this.state.showRunModal} onClick={this.showFullRunModal} title={this.state.modalTitle} graph={runGraph} />
            : <div></div>;

        {/*let fullScreenRunModal = this.state.currentGraph !== undefined && this.state.currentGraph !== null ?
            this.state.acGraph && this.state.currentSource !== undefined && this.state.currentSource !== null && this.state.currentGraph.timeSeriesDataSources.length ?
                (<FullScreenChartModal show={this.state.showRunModal} onClick={this.showFullRunModal} title={this.state.modalTitle} graph={<ACGraph ref={ref => ref && ref.clearPreviusState(this.state.clearPreviusState)} timeString={this.state.currentSource.timeString} graph={this.state.currentGraph} currentArray={this.state.currentSource.dataArray} assetSelected={this.state.alertSelected} isFullScreen={true} openModal={this.openFullRunModal}
                    graphData={this.state.currentSource} showAnnotations={this.state.showGraphAnnotations} graphAnnotations={this.state.currentSource.graphAnnotations} graphArrowAnnotations={this.state.currentSource.graphArrowAnnotations} hasAnalytics={this.state.currentAsset.hasAnalytics} handleModalCheckbox={this.handleCheckbox}
                    currentAsset={this.state.currentAsset} showAnalytics={this.state.visibility.analytics} showFurnace={this.state.visibility.furnace} showTemp={this.state.visibility.temp} showRLA={this.state.visibility.rla} showLRA={this.state.visibility.lra}>
                </ACGraph>}>
                </FullScreenChartModal>)
                : (this.state.currentGraph.run !== undefined && this.state.currentGraph.run !== null && this.state.currentGraph.run.series !== undefined && this.state.currentGraph.run.series !== null && this.state.currentGraph.run.series[0] !== undefined && this.state.currentGraph.run.series[0] !== null ?
                    <FullScreenChartModal show={this.state.showRunModal} onClick={this.showFullRunModal} title={this.state.modalTitle} graph={< CommonGraph data={this.state.currentGraph.run} showAnnotations={this.state.showRunAnnotations} showZoomButton={this.showZoomButton.bind(this)} ref={instance => { this.child = instance; }} />}>
                    </FullScreenChartModal>
                    : <div></div>)
            : <div></div>;*/}

        let dailyGraph = this.state.currentGraph !== undefined && this.state.currentGraph !== null && this.state.currentGraph.daily !== undefined && this.state.currentGraph.daily !== null ?
            this.state.acGraph && this.state.currentGraph.daily.dataArray.length ?
                <HighchartGraph graphType={"ASSET"} chartData={this.state.timelineDataList} key={`${this.state.currentGraph.daily?.timeString}-${this.state.visibility}-timeline-fullscreen`} isAirConditioner={true} dataMax={this.state.currentGraph.daily.yDataMax}
                    showRLA={this.state.visibility.rla} rlaValue={this.state.currentGraph.daily.dataArray[0]["RLA"]} showLRA={this.state.visibility.lra} lraValue={this.state.currentGraph.daily.dataArray[0]["LRA"]} showFLA={false} flaValue={null} graphAnnotations={this.getHighchartsAnnotations(this.state.currentGraph.daily.graphAnnotations)}
                    showAnalytics={this.state.visibility.analytics} hasAnalytics={this.state.hasAnalytics} showFurnace={this.state.visibility.furnace} showTemp={this.state.visibility.temp} timeString={this.state.currentSource?.timeString}
                    pointStart={this.state.currentGraph.daily.series !== undefined && this.state.currentGraph.daily.series !== null && this.state.currentGraph.daily.series.length && this.state.currentGraph.daily.series[0].data !== undefined && this.state.currentGraph.daily.series[0].data.length ?
                             this.state.currentGraph.daily.series[0]?.data[0]?.timestamp : (this.state.currentGraph.daily.dataArray !== undefined && this.state.currentGraph.daily.dataArray !== null && this.state.currentGraph.daily.dataArray.length ? this.state.currentGraph.daily.dataArray[0].Timestamp : null)} /> 
                : (this.state.currentGraph.daily.series !== undefined && this.state.currentGraph.daily.series !== null && this.state.currentGraph.daily.series[0] !== undefined && this.state.currentGraph.daily.series[0].data !== undefined && this.state.currentGraph.daily.series[0].data[0] !== undefined ?
                    <HighchartGraph graphType={"ASSET"} chartData={this.state.timelineDataList} key={`${this.state.currentGraph.daily?.timeString}-${this.state.visibility}-fullscreen`} isAirConditioner={false} dataMax={this.state.currentGraph.daily.yDataMax}
                        showRLA={this.state.visibility.rla} rlaValue={this.state.currentGraph.rla} showLRA={this.state.visibility.lra} lraValue={this.state.currentGraph.lra} showFLA={false} flaValue={null} graphAnnotations={this.getHighchartsAnnotations(this.state.currentGraph.daily.graphAnnotations)}
                        showAnalytics={this.state.visibility.analytics} hasAnalytics={this.state.hasAnalytics} showFurnace={this.state.visibility.furnace} showTemp={this.state.visibility.temp} timeString={this.state.currentGraph.daily?.timeString} pointStart={this.state.currentGraph.daily.series[0].data[0]?.timestamp} />
                        :<div></div>)
            : <div></div>;

        let fullScreenDailyModal = this.state.currentGraph !== undefined && this.state.currentGraph !== null && this.state.currentGraph.daily !== undefined && this.state.currentGraph.daily !== null ?
            <FullScreenChartModal show={this.state.showDailyModal} onClick={this.showFullDailyModal} title={this.state.modalTitle} graph={dailyGraph} />
            : <div></div>;

        {/*let fullScreenDailyModal = this.state.currentGraph !== undefined && this.state.currentGraph !== null && this.state.currentGraph.daily !== undefined && this.state.currentGraph.daily !== null ?
            this.state.acGraph && this.state.currentGraph.daily.dataArray.length ?
                (<FullScreenChartModal show={this.state.showDailyModal} onClick={this.showFullDailyModal} title={this.state.modalTitle} graph={<ACTimelineGraph timeString={this.state.currentGraph.daily.timeString} currentArray2={this.state.currentGraph.daily.dataArray} alertSelected={this.state.alertSelected} currentGraph={this.state.currentGraph.daily} isFullScreen={true} openModal={this.openFullDailyModal}
                    currentGraphData={this.state.currentGraph.daily} showAnnotations={this.state.showRunAnnotations} graphAnnotations={this.state.currentGraph.daily.graphAnnotations} graphArrowAnnotations={this.state.currentGraph.daily.graphArrowAnnotations} hasAnalytics={this.state.currentAsset.hasAnalytics} handleModalCheckbox={this.handleCheckbox}
                    showAnalytics={this.state.visibility.analytics} showFurnace={this.state.visibility.furnace} showRLA={this.state.visibility.rla} showLRA={this.state.visibility.lra} showTemp={this.state.visibility.temp} />} >
                </FullScreenChartModal>)
                    : (this.state.currentGraph.daily.series !== undefined && this.state.currentGraph.daily.series !== null && this.state.currentGraph.daily.series[0] !== undefined && this.state.currentGraph.daily.series[0] !== null ?
                        <FullScreenChartModal show={this.state.showDailyModal} onClick={this.showFullDailyModal} title={this.state.modalTitle} graph={<CommonGraph data={this.state.currentGraph.daily} isPreviewMode={true} showAnnotations={this.state.showRunAnnotations} showZoomButton={this.showZoomButton.bind(this)} ref={instance => { this.child = instance; }} />} >
                        </FullScreenChartModal>
                        :<div></div>)
            : <div></div>;*/}

        let multiGraph = this.state.multiseriesGraph !== undefined && this.state.multiseriesGraph !== null && this.state.multiseriesGraph.series !== undefined && this.state.multiseriesGraph.series !== null ?
            <HighchartGraph graphType={"MULTISERIES"} chartData={this.state.multiDataList} key={`Multiseries-${this.state.multiseriesGraph.series.length}-fullscreen`} graph={this.state.multiseriesGraph} isAirConditioner={true} dataMax={this.state.multiseriesGraph.yDataMax}
                showRLA={false} rlaValue={this.state.currentGraph.rla} showLRA={false} lraValue={this.state.currentGraph.lra} showFLA={false} flaValue={null} graphAnnotations={this.getHighchartsAnnotations(this.state.multiseriesGraph.graphAnnotations)}
                showAnalytics={this.state.visibility.analytics} hasAnalytics={this.state.hasAnalytics} showFurnace={this.state.visibility.furnace} showTemp={this.state.visibility.temp} timeString={null} pointStart={this.state.multiseriesGraph.series[0].data[0]?.timestamp} />
            : <div></div>;

        let fullScreenMultiModal = this.state.multiseriesGraph !== undefined && this.state.multiseriesGraph !== null && this.state.multiseriesGraph.series !== undefined && this.state.multiseriesGraph.series !== null ?
            <FullScreenChartModal show={this.state.showMultiModal} onClick={this.showFullMultiModal} title={this.state.modalTitle} graph={multiGraph} >
            </FullScreenChartModal>
            : <div></div>;

        return (
            <div>
                {contents}

                {fullScreenRunModal}
                {fullScreenDailyModal}
                {fullScreenMultiModal}

                <JobDetailModal show={this.state.showJobDetailConfirm} onClick={this.showJobDetailModal} cases={this.state.alertCases} />

                <AlertEditModal show={this.state.showEditConfirm} onClick={this.showEditAlertModal} title={this.state.modalTitle}
                    editAlert={this.state.editAlert} onMessageChange={this.onMessageChange} onRecommendationChange={this.onRecommendationChange}
                    onPriorityChange={this.onPriorityChange} onFalsePositiveChange={this.onFalsePositiveChange} onSave={this.onEditAlert}>
                </AlertEditModal>

                <CreateJobModal show={this.state.showCreateJobConfirm} onClick={this.showCreateJobModal} title={this.state.modalTitle}
                    editAlert={this.state.editAlert} createJob={this.state.currentOpportunity}
                    onSave={this.onCreateJob} onPriorityOpportunityChange={this.onPriorityOpportunityChange}
                    onCommentsChange={this.onCommentsChange} onSubjectChange={this.onSubjectChange}>
                </CreateJobModal>

                <ConfirmationModal show={this.state.showCloseConfirm} onYes={this.onCloseAlert} onClick={this.showCloseAlertModal} message={this.state.modalMessage} title={this.state.modalTitle}></ConfirmationModal>

                {/*<ConfirmationModal show={this.state.showDevicesConfirm} onYes={this.onViewDevices} onClick={this.showDevicesModal} message={this.state.modalMessage} title={this.state.modalTitle}></ConfirmationModal>*/}

                <DevicesModal show={this.state.showDevicesConfirm} onClick={this.showDevicesModal} message={this.state.modalMessage} alert={this.state.editAlert} customer={this.state.currentCustomer} isCurrentJob={!this.props.showButtons}
                    setCurrentPartnerAndCustomer={this.props.setCurrentPartnerAndCustomer} setCurrentPartnerId={this.props.setCurrentPartnerId} setCurrentCustomerId={this.props.setCurrentCustomerId} setActivePath={this.props.setActivePath} >
                </DevicesModal>
            </div>
        );
    }
}

export default withStyles(styles)(BaseAlertPage);